/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-_DEV && console */

import { _DEV } from "../isDev";

var moment = require("moment");
export function verifyemail(body) {
  _DEV && console.log(body);
  return (dispatch) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        email: body,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(process.env.REACT_APP_apiurl + "/user/otpgenerate", reqValues)
      .then((result) => result.json())
      .then((result) => {
        _DEV && console.log(result);
        //pass the result to message in
      })
      .catch((err) => {
        _DEV && console.log("Error" > err);
      });
  };
}

export function verifyotp(email, otp) {
  return (dispatch) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        email: email,
        otp: otp,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(process.env.REACT_APP_apiurl + "/user/otpgenerate", reqValues)
      .then((result) => result.json())
      .then((result) => {
        _DEV && console.log(result);

        // _DEV && console.log(result[0].footertext);
        // dispatch(footertext(result[0].footertext));
        //company id should be dispatched
      })
      .catch((err) => {
        _DEV && console.log("Error" > err);
      });
  };
}

export function updatejobstatus(id, status, location) {
  //corresponds to kebla api
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        statuschange: "yes",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/job/updatejob?id=" + id, reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        _DEV && console.log(getState());

        if (result.message === "Edited") {
          dispatch(toast("success", "Job's status changed successfully!!"));
          var index = getState().jobListdata.findIndex((x) => x._id === id);
          let list = [...getState().jobListdata];
          _DEV && console.log(list[index]);
          list[index].status = status;

          dispatch(jobList(list));
        }
      })
      .catch((err) => {
        _DEV && console.log("Error" > err);
      });
  };
}

export function getReports(start, end, id, location) {
  _DEV && console.log(start, end, id);
  return (dispatch, getState) => {
    const reqValues = {
      method: "POST",
      body: JSON.stringify({
        after: start,
        before: end,
        id: id,
        //  name:name
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/job/findReport", reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log("Image update done?? //from action");
        _DEV && console.log(result, getState());
        dispatch(reportList(result));
        // dispatch(workerReport(result))
      })
      .catch((err) => {
        _DEV && console.log(err);
      });
  };
}

function reportList(data) {
  return {
    type: "REPORT_LIST",
    data: data,
  };
}
function workerReport(data) {
  return {
    type: "WORKERREPORT_LIST",
    data: data,
  };
}
export function getWorkerReports(start, end, id, location) {
  return (dispatch, getState) => {
    const reqValues = {
      method: "POST",
      body: JSON.stringify({
        after: start,
        before: end,
        id: id,
        //  name:name
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/job/findReport", reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log("Image update done?? //from action");
        _DEV && console.log(result, getState());
        // dispatch(reportList(result));
        dispatch(workerReport(result));
      })
      .catch((err) => {
        _DEV && console.log(err);
      });
  };
}

export function exportSocket(result) {
  _DEV && console.log(result, '1987');
  return dispatch => {
    dispatch(socketDetails(result));
  };
}
function socketDetails(data) {
  //_DEV && console.log(data, "....L54");
  return {
    type: 'SOCKET_DATA',
    data: data
  };
}

function notificationList(data) {
  return {
    type: "NOTIFICATION_LIST",
    data: data,
  };
}
export function getNotification(id, location) {
  _DEV && console.log(id);
  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/notification/getbysender?senderId=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log("from action");
        _DEV && console.log(result, getState());
        // dispatch(reportList(result));
        dispatch(notificationList(result.result));
      })
      .catch((err) => {
        _DEV && console.log(err);
      });
  };
}

function workerHours(data) {
  return {
    type: "WORKERHOURS_LIST",
    data: data,
  };
}
export function getworkerHours(
  start,
  end,
  companyId,
  selectedworker,
  location
) {
  _DEV && console.log(start, end, companyId, selectedworker);
  return (dispatch, getState) => {
    const reqValues = {
      method: "POST",
      body: JSON.stringify({
        after: start,
        before: end,
        companyId: companyId,
        workerId: selectedworker,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/job/findReportWithWorkerId",
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log("from action");
        _DEV && console.log(result, getState());
        // dispatch(reportList(result));
        dispatch(workerHours(result.result));
      })
      .catch((err) => {
        _DEV && console.log(err);
      });
  };
}

export function removeWorker(wId, jId, location) {
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        wId: wId,
        jId: jId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/job/removeWorker", reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log("Removing worker from the job");
        _DEV && console.log(result, "result");
        _DEV && console.log(getState());
        if (result.message === "Deleted Succesfully") {
          dispatch(toast("success", "Worker removed successfully"));
          var index = getState().jobListdata.findIndex((x) => x._id === jId);
          let list = [...getState().jobListdata];

          let x = list[index].workSummary.filter((x) => {
            return x.workerId._id !== wId;
          });
          _DEV && console.log(x);
          list[index].workSummary = x;

          // dispatch()
          dispatch(jobList(list));
        } else {
          dispatch(toast("error", "Couldn't remove worker!"));
        }
      })
      .catch((err) => {
        _DEV && console.log(err);
      });
  };
}

export function getfootertext(location) {
  return (dispatch) => {
    const reqValues = {
      method: "GET",
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl +
        "/sitecontent/get?id=5ca45039bcc6e569a796b310",
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        _DEV && console.log(result[0].footertext);
        dispatch(footertext(result[0].footertext));
        //company id should be dispatched
      })
      .catch((err) => {
        _DEV && console.log("Error" > err);
      });
  };
}

function footertext(data) {
  return {
    type: "COPYRIGHT_STATEMENT",
    data: data,
  };
}

export function savefootertext(text, location) {
  return (dispatch) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        text: text,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    fetch(
      process.env.REACT_APP_apiurl +
        "/sitecontent/updatesitecontent?id=5ca45039bcc6e569a796b310",
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        dispatch(toast("success", "Footer text changed successfully!"));
        dispatch(footertext(result.footertext));
      })
      .catch((err) => {
        _DEV && console.log(err);
      });
  };
}

export function addprofilepicture(id, image, location) {
  _DEV && console.log(id, image);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        image: image,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    fetch(
      process.env.REACT_APP_apiurl + "/user/addprofilepicture?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log("Image update done?? //from action");
        _DEV && console.log(result, getState());
      })
      .catch((err) => {
        _DEV && console.log(err);
      });
  };
}

export function resetPassword(id, value) {
  return (dispatch) => {
    const reqValues = {
      method: "POST",
      body: JSON.stringify({
        oldPassword: value.oldpassword,
        password: value.newpassword,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(
      process.env.REACT_APP_apiurl + "/user/resetPassword?id=" + id,
      reqValues
    )
      .then((result) => result.json())

      .then((result) => {
        _DEV && console.log(result);
        if (result.message === "Password updated") {
          dispatch(toast("success", result.message));
        }
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function savepreimages(id, images, remove, workerId, location) {
  _DEV && console.log(images, "Array??");
  _DEV && console.log(workerId);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        images: images,
        imageType: "pre",
        workerId: workerId || getState().userDetail._id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    fetch(process.env.REACT_APP_apiurl + "/job/addimages?id=" + id, reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);

        _DEV && console.log("Image update done?? //from action");
        var index = getState().jobListdata.findIndex((x) => x._id === id);
        let list = [...getState().jobListdata];
        _DEV && console.log(list[index], "in action remove one with if below");
        _DEV && console.log(remove, "to be removed id");

        _DEV && console.log(list[index].attachFile);
        list[index].attachFile = list[index].attachFile.filter((x) => {
          return x._id !== remove;
        });
        dispatch(jobList(list));
      })
      .catch((err) => {
        _DEV && console.log(err);
      });
  };
}
export function savepostimages(id, images, obj, workerId, location) {
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        imageType: "post",
        workerId: workerId || getState().userDetail._id,
        images: images,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    fetch(process.env.REACT_APP_apiurl + "/job/addimages?id=" + id, reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log("Image update done?? //from action");
        _DEV && console.log(result);
        let list = [...getState().jobListdata, result.result];
        var index = list.findIndex((x) => x._id === id);
        _DEV && console.log(index);
        // _DEV && console.log(list);
        // list[index].images = [...list[index].images, obj];
        dispatch(jobList(list));
      })
      .catch((err) => {
        _DEV && console.log(err);
      });
  };
}
export function saveactivity(id, activity, comment, worker, location) {
  _DEV && console.log(id, activity, worker, "saving");
  // _DEV && console.log(Math.floor(Date.now()), 'Date Print');
  return () => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        dateStart: +moment(Date.now()).startOf("day"),
        activity: {
          workActivity: activity,
          time: Math.floor(Date.now()),
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    fetch(
      process.env.REACT_APP_apiurl +
        "/job/saveactivity?id=" +
        id +
        "&worker=" +
        worker,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        _DEV && console.log("Updating activity for a job");
        // _DEV && console.log(result, getState());
      })
      .catch((err) => {
        _DEV && console.log(err);
      });
  };
}

export function savestarttime(id, action, worker, location) {
  _DEV && console.log(id, action, worker);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        startTime: Math.floor(Date.now()),
        dateStart: +moment(Date.now()).startOf("day"),
        object: {
          clockAction: action,
          time: Math.floor(Date.now()),
        },
        // action: action
        // status is updated from backend
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl +
        "/job/saveaction?id=" +
        id +
        "&worker=" +
        worker,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        //TODO
        //dispatch status via getstate
        if (result.message === "Edited") {
          let obj = { ...getState().workerJobList };
          // dispatch(toast('success', 'Job started!'));
          var index = getState().workerJobList.dailyAssignedJobs.findIndex(
            (x) => x._id === id
          );
          let list = [...getState().workerJobList.dailyAssignedJobs];
          list[index].status = "in_progress";
          // list> select correct job> in the job, in job update correct worker's status
          _DEV && console.log(list[index], "the selected job in action");
          _DEV && console.log(index, "index of the job");
          for (let i = 0; i < list[index].workSummary.length; i++) {
            _DEV &&
              console.log(list[index].workSummary[i], "in action save......");
            if (list[index].workSummary[i].workerId._id === worker) {
              list[index].workSummary[i].workerStatus = "in_progress";
              break;
            }
          }
          _DEV && console.log(list, "the whole list to be dispatched");

          obj.dailyAssignedJobs = list;

          dispatch(workerJobList(obj));
        }
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function savestartbreak(id, action, comment, worker, location) {
  _DEV && console.log(id, action, comment);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        dateStart: +moment(Date.now()).startOf("day"),
        breakstart: "yes",
        object: {
          clockAction: action,
          workerComment: comment,
          time: Math.floor(Date.now()),
        },
        // action: action
        // status is updated from backend
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl +
        "/job/saveaction?id=" +
        id +
        "&worker=" +
        worker,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        //TODO
        //dispatch status via getstate
        if (result.message === "Edited") {
          let obj = { ...getState().workerJobList };
          var index = getState().workerJobList.dailyAssignedJobs.findIndex(
            (x) => x._id === id
          );
          let list = [...getState().workerJobList.dailyAssignedJobs];

          // list[index].status = "On break";
          for (let i = 0; i < list[index].workSummary.length; i++) {
            if (list[index].workSummary[i].workerId._id === worker) {
              if (action.indexOf("resume later") > -1) {
                list[index].workSummary[i].workerStatus = "resume later";
                let arr = [...getState().removeContinuingJob];
                arr.push(id);
                dispatch(removeContinuingJob(arr));
                dispatch(toast("success", "Job to be resumed later!"));
              } else {
                dispatch(toast("success", "Break started!"));
                list[index].workSummary[i].workerStatus = "On break";
              }

              break;
            }
          }

          obj.dailyAssignedJobs = list;

          dispatch(workerJobList(obj));
        }
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

function removeContinuingJob(data) {
  return {
    type: "REMOVE_CONTINUING_JOB",
    data: data,
  };
}

export function saveendbreak(result, id, worker) {
  _DEV && console.log(result, id);
  return (dispatch, getState) => {
    if (result.message === "Edited") {
      let obj = { ...getState().workerJobList };
      var index = getState().workerJobList.dailyAssignedJobs.findIndex(
        (x) => x._id === id
      );
      let list = [...getState().workerJobList.dailyAssignedJobs];
      for (let i = 0; i < list[index].workSummary.length; i++) {
        if (list[index].workSummary[i].workerId._id === worker) {
          list[index].workSummary[i].workerStatus = "in_progress";
          break;
        }
      }
      obj.dailyAssignedJobs = list;
      dispatch(workerJobList(obj));
    }
  };
}

export function saveendtime(id, action, text, worker, location) {
  _DEV && console.log(id, action, text, worker);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        endTime: Math.floor(Date.now()),
        dateStart: +moment(Date.now()).startOf("day"),
        comment: text,
        object: {
          clockAction: action,
          time: Math.floor(Date.now()),
          workerComment: text,
        },

        // comment: text
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl +
        "/job/saveaction?id=" +
        id +
        "&worker=" +
        worker,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        if (
          !result.error
          // result.message === 'Edited' ||
          // result.message === 'duplicate company created'
        ) {
          // dispatch(toast('success', 'Job Finished!'));
          let obj = { ...getState().workerJobList };
          var index = getState().workerJobList.dailyAssignedJobs.findIndex(
            (x) => x._id === id
          );
          let list = [...getState().workerJobList.dailyAssignedJobs];
          if (action.indexOf("Clock out") > -1) {
            for (let i = 0; i < list[index].workSummary.length; i++) {
              if (list[index].workSummary[i].workerId._id === worker) {
                list[index].workSummary[i].workerStatus = "completed";
                break;
              }
            }
          } else if (action.indexOf("progress") > -1) {
            // list[index].status="In progress"
            for (let i = 0; i < list[index].workSummary.length; i++) {
              if (list[index].workSummary[i].workerId._id === worker) {
                list[index].workSummary[i].workerStatus = "in_progress";
                break;
              }
            }
          } else {
            for (let i = 0; i < list[index].workSummary.length; i++) {
              if (list[index].workSummary[i].workerId._id === worker) {
                list[index].workSummary[i].workerStatus = "resume_later";
                break;
              }
            }
          }
          obj.dailyAssignedJobs = list;
          dispatch(workerJobList(obj));
        }
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

// export function adduser(data) {
//   return (dispatch, getState) => {
//     _DEV && console.log(data);
//     const reqValues = {
//       method: "POST",
//       body: JSON.stringify({
//         name: data.name,
//         email: data.email,
//         phone: data.phone,
//         password: data.password,
//         title: data.title,
//         address: data.address,
//         role: data.role,
//         companyId: data.companyId
//       }),
//       headers: {
//         "Content-Type": "application/json"
//       }
//     };

//     fetch(process.env.REACT_APP_apiurl + "/user", reqValues)
//       .then(result => result.json())
//       .then(result => {
//         _DEV && console.log(result, getState());
//         if (result) {
//           if (result.role === "worker")
//             dispatch(workerlist([...getState().workerList, result]));
//         }
//       })
//       .catch(err => {
//         _DEV && console.log(err);
//       });
//   };
// }

// Toast calling- switch case used here itself(function)

function toast(type, message) {
  switch (type) {
    case "success":
      return { type: "TOAST", toastype: type, message };
    case "info":
      return { type: "TOAST", toastype: type, message };
    case "warning":
      return { type: "TOAST", toastype: type, message };
    case "error":
      return { type: "TOAST", toastype: type, message };
  }
}

function errorMessage(msg) {
  console.log(msg, "L745>>");
  return {
    type: "ERROR",
    data: msg,
  };
}
// export function error(data) {
//     return dispatch => {
//       dispatch(errorMessage(data));
//     };
//   }
function companylist(data) {
  _DEV && console.log(data, "848??");
  return {
    type: "COMPANY LIST",
    data: data,
  };
}
function customerlist(data) {
  _DEV && console.log(data);
  return {
    type: "CUSTOMER LIST",
    data: data,
  };
}

// function selectedcompanydata._id(id) {
//   return {
//     type: "COMPANY ID",
//     data: id
//   };
// }
function selectedcompanydata(data) {
  return {
    type: "COMPANY DATA",
    data: data,
  };
}

function selectedcompanyuser(data) {
  return {
    type: "COMPANY USER DATA",
    data: data,
  };
}

export function selectedcompany(data, location) {
  return (dispatch) => {
    _DEV && console.log(data);
    dispatch(selectedcompanydata(data));

    const reqValues = {
      method: "GET",
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/user/adminByOrganisation?id=" + data._id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        if (result) {
          //seems fishy !!
          localStorage.setItem("selectedCompanyId", data._id);
          dispatch(selectedcompanyuser(result));
        }
      })
      .catch((err) => {
        _DEV && console.log(err);
      });
  };
}
function paymentList(data) {
  return {
    type: "PAYMENT LIST",
    data: data,
  };
}
// function paymentList(data) {
//   return {
//     type: "PAYMENT LIST",
//     data: data
//   };
// }

function workerlist(data) {
  return {
    type: "WORKER LIST",
    data: data,
  };
}

function jobList(data) {
  return {
    type: "JOB LIST",
    data: data,
  };
}

function workerJobList(data) {
  _DEV && console.log(data, "In reducer");
  return {
    type: "WORKER_JOB_LIST",
    data: data,
  };
}

function managerList(data) {
  return {
    type: "MANAGER LIST",
    data: data,
  };
}

// export function loginuser(){

// }

export function adduser(data, location) {
  return (dispatch, getState) => {
    _DEV && console.log(data);
    const reqValues = {
      method: "POST",
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        phone: data.phone,
        password: data.password,
        title: data.title,
        address: data.address,
        role: data.role,
        companyId: data.companyId,
        profilePicture: data.profilePicture || null,
        attachFile: data.attachFile || [],
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    fetch(process.env.REACT_APP_apiurl + "/user", reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result, getState());
        if (result) {
          if (result.message) {
            dispatch(toast("error", result.message));
          } else if (result.role === "worker") {
            var arr = [...getState().workerList];
            arr.unshift(result);
            dispatch(workerlist(arr));
            // dispatch(workerlist([...getState().workerList, result]));
          }
        }
      })
      .catch((err) => {
        _DEV && console.log(err);
      });
  };
}

export function deleteworker(id, location) {
  _DEV && console.log(id);
  return (dispatch, getState) => {
    const reqValues = {
      method: "DELETE",
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/user/deleteuser?id=" + id, reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        _DEV && console.log(getState().workerList);

        var index = getState().workerList.findIndex((x) => x._id === id);
        //_DEV && console.log(index, typeof(index))
        //Immutability!!
        let list = [...getState().workerList];
        // _DEV && console.log(list,"list",typeof(list))
        list.splice(index, 1);
        _DEV && console.log(list, "list", Array.isArray(list));
        //_DEV && console.log(getState());
        dispatch(workerlist(list));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function getWorker(id, location) {
  return (dispatch) => {
    const reqValues = {
      method: "GET",
      headers: {
        accept: "application/xml",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl +
        "/user/getuserbyrole?role=worker&company=" +
        id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        dispatch(workerlist(result));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

//Add company- Component-Addeditcompany form /dashboard/company
export function addcompany(values, location) {
  return (dispatch, getState) => {
    const reqValues = {
      method: "POST",
      body: JSON.stringify({
        name: values.companyname,
        //email: values.email.toLowerCase(),
        email: values.email,
        phone: values.phone,
        address: values.address,
        contactPassword: values.contactPassword,
        contactName: values.contactName,
        contactEmail: values.contactEmail,
        clearPassword: values.contactPassword,
        companyEmail: values.companyEmail,
        profilePicture: values.profilePicture,
        attachFile: values.attachFile,
        contactPhone: values.contactPhone,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/company/createwithuser", reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result, "L1103");
        if (!result.error) {
          dispatch(toast("success", "Company Created successfully"));
          dispatch(companylist([...getState().companylistdata, result]));
        } else if (result.message.indexOf("E11000") > -1) {
          dispatch(toast("error", "admin with this email already exists!!"));
        } else {
          dispatch(toast("error", "Server Error"));
        }
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}
//GET /company/list

export function getcompany(location) {
  return (dispatch) => {
    const reqValues = {
      method: "GET",
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/company/get", reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        if (!result.error) {
          dispatch(companylist(result.result));
          //company id should be dispatched
        }
      })
      .catch((err) => {
        _DEV && console.log("Error" > err);
      });
  };
}

export function getsinglecompany(id, location) {
  _DEV && console.log(id);
  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/company/getSingle?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        dispatch(companylist(result.result));
        //company id should be dispatched
      })
      .catch((err) => {
        _DEV && console.log("Error" > err);
      });
  };
}

export function addcustomer(values, id, location) {
  _DEV && console.log(values, "called");
  return (dispatch, getState) => {
    const reqValues = {
      method: "POST",
      body: JSON.stringify({
        companyname: values.companyname,
        contactname: values.contactname,
        email: values.email ? values.email : "",
        primaryPhone: values.primaryPhone,
        secondaryPhone: values.secondaryPhone,
        description: values.description,
        address: values.address,
        companyId: id,
        latitude: values.latitude,
        longitude: values.longitude,
        createdDate: Date.now(),
        leads: values.leads,
        salesMan: values.salesMan,
        profilePicture: values.profilePicture,
        attachFile: values.attachFile || [],
        attachedStatus: values.attachedStatus || [],
        alternativeNumber: values.alternativeNumber || [],
        managerId: values.managerId
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    fetch(process.env.REACT_APP_apiurl + "/customer", reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result, getState(), "988999999");
        // dispatch(customerlist(result));
        if (result.message) {
          dispatch(toast("error", result.message));
          result.timestamp = Date.now();
          result.error = true;
          dispatch(creationError(result.message));
        } else {
          dispatch(toast("Success", "Customer created successfully"));
          result.email = values.email ? values.email : "";
          var arr = [...getState().customerList];
          arr.unshift(result);
          dispatch(customerlist(arr));
          result.timestamp = Date.now();
          result.error = false;
          dispatch(creationError(result));
          this.props.actions.showMessages(
            "success",
            "Customer Added successfully"
          );
          // let selectedcompanydata = { ...getState().selectedcompanydata };
          // location.push({
          //   pathname: "/dashboard/customerlist",
          //   search: "?companyId=" + result.companyId,
          //   data: "customer",
          //   selectedData: selectedcompanydata,
          // });
        }
      })
      .catch((err) => {
        _DEV && console.log("Error" > err);
      });
  };
}

function creationError(data) {
  _DEV && console.log(data);
  return {
    type: "CUSTOMER/COMPANY_CREATEERROR",
    data: data,
  };
}

export function addjob(values, id, location) {
  _DEV && console.log(values, id, "L1078");
  return (dispatch, getState) => {
    const reqValues = {
      method: "POST",
      body: JSON.stringify({
        customerId: values.customerId,
        customername: values.customerName,
        customeremail: values.email,
        phone: values.phone,
        siteaddress: values.siteaddress,
        companyId: id,
        jobName: values.jobName,
        contractPrice: values.contractPrice,
        latitude: values.coordinates.lat,
        longitude: values.coordinates.lng,
        createdDate: Math.floor(Date.now()),
        description: values.description,
        attachFile: values.attachFile,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/job", reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        _DEV && console.log(getState());
        var arr = [...getState().jobListdata];
        arr.unshift(result);
        dispatch(jobList(arr));
        // dispatch(jobList([...getState().jobListdata, result]));
      })
      .catch((err) => {
        _DEV && console.log("Error" > err);
      });
  };
}

export function addpayment(a, b, c, d, e, location) {
  _DEV && console.log(a, b, c, d, e);
  return (dispatch, getState) => {
    const reqValues = {
      method: "POST",
      body: JSON.stringify({
        companyId: e,
        customerId: d,
        amount: b,
        paymentMethod: a,
        date: c,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/payment", reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        _DEV && console.log(getState());
        var index = getState().customerList.findIndex((x) => x._id === d);
        _DEV && console.log(getState().customerList[index]);
        let data = result.result;

        let y = { companyname: getState().customerList[index].companyname };

        data.customerId = y;

        dispatch(paymentList([...getState().paymentList, data]));

        // // _DEV && console.log([...getState().paymentList]);
        // let list =[...getState().paymentList];
        // list.splice(index);
        // _DEV && console.log(list, "list", Array.isArray(list));

        // dispatch(paymentList(list));
        // dispatch(paymentList([...getState().paymentList,list,result.result]));
      })
      .catch((err) => {
        _DEV && console.log("Error" > err);
      });
  };
}

export function getpayment(id, location) {
  return (dispatch) => {
    const reqValues = {
      method: "GET",
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/payment/getbycompanyId?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        dispatch(paymentList(result));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}
export function deletepayment(id, location) {
  _DEV && console.log(id);
  return (dispatch, getState) => {
    const reqValues = {
      method: "DELETE",
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/payment/deletepayment?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        _DEV && console.log(getState());

        var index = getState().paymentList.findIndex((x) => x._id === id);
        _DEV && console.log(index);
        let list = [...getState().paymentList];

        list.splice(index, 1);
        _DEV && console.log(list, "list", Array.isArray(list));

        dispatch(paymentList(list));
        // dispatch(paymentList([...getState().paymentList, result]));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function deletejob(id, location) {
  _DEV && console.log(id);
  return (dispatch, getState) => {
    const reqValues = {
      method: "DELETE",
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/job/deletejob?id=" + id, reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        _DEV && console.log(getState());

        var index = getState().jobListdata.findIndex((x) => x._id === id);
        _DEV && console.log(index);
        let list = [...getState().jobListdata];

        list.splice(index, 1);
        _DEV && console.log(list, "list", Array.isArray(list));
        dispatch(jobList(list));
        dispatch(toast("success", result.message));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

//GET /customer/list

export function getcustomer(id, location) {
  _DEV && console.log(id, "result");
  return (dispatch) => {
    const reqValues = {
      method: "GET",
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/customer/get?id=" + id, reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result, "1248..");
        dispatch(customerlist(result));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

function loggedinuser(data) {
  _DEV && console.log(data);
  return {
    type: "LOGGED_USER_DETAILS",
    data: data,
  };
}
export function resumesession(location, data) {
  // _DEV && console.log(id);
  _DEV && console.log(location);
  _DEV && console.log(data);
  // id,location,
  return (dispatch) => {
    //resume session
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/user/resumesession", reqValues)
      .then((result) => {
        _DEV && console.log(result);
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        if (result.result) {
          if (
            result.result.role === "admin" ||
            result.result.role === "manager"
          ) {
            _DEV && console.log("Admin working");
            localStorage.setItem("userdata", result.result._id);
            // dispatch(toast("success", "Login successfull!"));
            dispatch(loggedinuser(result.result));
            let data = {};
            data = result.result.companyId;
            data.customerCount = result.nCustomrs;
            data.jobCount = result.nJobs;
            data.managerCount = result.nManagers;
            data.workerCount = result.nWorkers;
            dispatch(selectedcompanydata(data));
            dispatch(selectedcompanyuser(result.result));
            //_DEV && console.log(data)
            _DEV && console.log(result.result);
            _DEV && console.log("outgoing");
            const reqValues = {
              method: "GET",
              headers: {
                accept: "application/xml",
              },
              credentials: "include",
            };
            fetch(
              process.env.REACT_APP_apiurl +
                "/user/getuserbyrole?role=worker&company=" +
                result.result.companyId._id,
              reqValues
            )
              .then((result) => {
                if (result.status === 401) {
                  location.push({
                    pathname: "/home",
                  });
                } else {
                  return result.json();
                }
              })
              .then((result) => {
                _DEV && console.log(result);
                dispatch(workerlist(result));
              })
              .catch((err) => {
                _DEV && console.log("Error", err);
              });
            // location.push({
            //     pathname: '/dashboard',
            //     user: { role: result.result.role.toLowerCase() }
            // });
          } else if (result.result.role === "superadmin") {
            localStorage.setItem("userdata", result.result._id);
            _DEV && console.log(result.result);
            let obj = result.result;
            obj.companyCount = result.nCompanies;
            // dispatch(loggedinuser(result.result));
            dispatch(loggedinuser(obj));
            // dispatch(toast("success", "Login successfull!"));
            _DEV && console.log("Superadmin");

            //if data --> send user role to dashboard

            // location.push({
            //     pathname: '/dashboard',
            //     user: { role: result.result.role.toLowerCase() }
            // });
            // else{
            //   location.push({
            //     pathname: "/dashboard",
            //     user: { role: result.result.role.toLowerCase() }
            //   });
            // }
          }
          //adding
          else if (result.result.role === "worker") {
            _DEV && console.log(result.result, "worker");
            if (result.result.enabled === true) {
              localStorage.setItem("userdata", result.result._id);
              // dispatch(toast("success", "Login successfull!"));
              dispatch(loggedinuser(result.result));

              // if (!data) {
              //if data --> send user role to dashboard
              location.push({
                pathname: "/workerdashboard",
                user: { role: result.result.role.toLowerCase() },
              });
              // }
              //this.getWorkerJobList
            }
          } else {
            dispatch(errorMessage("Wrong email password combination"));
          }
        }
      })

      .catch((err) => {
        _DEV && console.log("Error", err);
        if (err.message === "Wrong email (password) combination!") {
          dispatch(errorMessage(err.message));
        } else {
          dispatch(errorMessage("Wrong email password combination"));
        }
      });
  };
}

export function loginuser(email, password, location, device) {
  _DEV && console.log(device);
  return (dispatch) => {
    //authentication function
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl +
        "/user/login?email=" +
        encodeURIComponent(email) +
        "&password=" +
        password,
      reqValues
    )
      .then(
        (result) =>
          //{
          //_DEV && console.log(result)
          result.json()
        //}
      )

      .then((result) => {
        _DEV && console.log(result);
        //_DEV && console.log(result.result.role,"worker")
        // if (result.message="Wrong email (password) combination!")
        // {
        //  dispatch(errorMessage(result.message))
        // }

        //toast should be dispatched in the if conditions
        if (result.result) {
          if (
            result.result.role === "admin" ||
            result.result.role === "manager"
          ) {
            if (device === "mobile" && result.result.role !== "manager") {
              dispatch(
                errorMessage(
                  "Sorry, only worker or manager is allowed to login through mobile phones!"
                )
              );
            } else {
              localStorage.setItem("userdata", result.result._id);
              localStorage.setItem("role", result.result.role);
              dispatch(toast("success", "Login Successfully"));
              dispatch(loggedinuser(result.result));
              let data = {};
              data = result.result.companyId;
              data.customerCount = result.nCustomrs;
              data.jobCount = result.nJobs;
              data.managerCount = result.nManagers;
              data.workerCount = result.nWorkers;
              dispatch(selectedcompanydata(data));
              dispatch(selectedcompanyuser(result.result));
              //_DEV && console.log(data)
              _DEV && console.log(result.result);
              _DEV && console.log("outgoing");
              const reqValues = {
                method: "GET",
                headers: {
                  accept: "application/xml",
                },
                credentials: "include",
              };
              fetch(
                process.env.REACT_APP_apiurl +
                  "/user/getuserbyrole?role=worker&company=" +
                  result.result.companyId._id,
                reqValues
              )
                .then((result) => result.json())
                .then((result) => {
                  _DEV && console.log(result);
                  dispatch(workerlist(result));
                })
                .catch((err) => {
                  _DEV && console.log("Error", err);
                });
              // _DEV && console.log(result.result.companyId, 'L1521');
              location.push({
                pathname: "dashboard",
                search: "?companyId=" + result.result.companyId._id,
                user: { role: result.result.role.toLowerCase() },
              });
            }
          } else if (result.result.role === "superadmin") {
            if (device === "mobile") {
              _DEV && console.log(result);
              dispatch(
                errorMessage(
                  "Sorry, only worker is allowed to login through mobile phones!"
                )
              );
            } else {
              localStorage.setItem("userdata", result.result._id);
              localStorage.setItem("role", result.result.role);
              let obj = result.result;
              obj.companyCount = result.nCompanies;
              // dispatch(loggedinuser(result.result));
              dispatch(loggedinuser(obj));
              dispatch(selectedcompanydata({}));
              dispatch(companylist([])); // white screen error
              dispatch(toast("success", "Login successfull!"));
              _DEV && console.log("Superadmin");
              location.push({
                pathname: "dashboard",
                user: { role: result.result.role.toLowerCase() },
              });
            }
          }
          //adding
          else if (result.result.role === "worker") {
            localStorage.setItem("userdata", result.result._id);
            localStorage.setItem("role", result.result.role);
            dispatch(toast("success", "Login successfull!"));
            dispatch(loggedinuser(result.result));
            _DEV && console.log(result.result, "worker");
            if (result.result.enabled === true) {
              location.push({
                pathname: "workerdashboard",
                user: { role: result.result.role.toLowerCase() },
              });
              //this.getWorkerJobList
            }
          }
        } else {
          dispatch(errorMessage("Wrong email password combination"));
        }
      })

      .catch((err) => {
        _DEV && console.log("Error", err);
        // if (err.message === 'Wrong email (password) combination!') {
        //     dispatch(errorMessage(err.message));
        // } else {
        dispatch(errorMessage("Wrong email password combination"));
        // }
      });
  };
}

export function updateError(err) {
  console.log(err, "L1579");
  return (dispatch) => {
    dispatch(errorMessage(err));
  };
}

// export function seterror(data) {
//   return dispatch => {
//     dispatch(errorMessage(data));
//   };
// }

//GET /job/list

export function getjob(id, location) {
  return (dispatch) => {
    const reqValues = {
      method: "GET",
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/job/getjobbycompanyId?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        dispatch(jobList(result));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function getWorkerJobList(id, location) {
  _DEV && console.log(id);
  return (dispatch) => {
    const reqValues = {
      method: "GET",
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/job/getjobbyworkerId?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result.result);
        if (!result.error) {
          var arr = [
            ...result.result.dailyAssignedJobs,
            ...result.result.dailyUnAssignedJobs,
          ];

          _DEV && console.log(arr);

          let obj = { ...result.result, ...{ all: arr } };
          _DEV && console.log(obj);

          dispatch(workerJobList(obj));
        }
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}
export function assignjobtoworker(
  worker,
  job,
  startTime,
  id,
  endTime,
  location
) {
  _DEV && console.log(worker, job, startTime, id, endTime);
  //Todo update assign
  return (dispatch) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        worker: worker,
        id: job,
        startDate: startTime,
        endDate: endTime,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/job/updatejobassign?id=" + job,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        if (result.message === "Updated") {
          dispatch(
            toast("success", "Workers assigned to selected jobs successfully!")
          );

          const reqValues = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          };
          fetch(
            process.env.REACT_APP_apiurl + "/job/getjobbycompanyId?id=" + id,
            reqValues
          )
            .then((result) => {
              if (result.status === 401) {
                location.push({
                  pathname: "/home",
                });
              } else {
                return result.json();
              }
            })
            .then((result) => {
              _DEV && console.log(result);
              dispatch(jobList(result));
            })
            .catch((err) => {
              _DEV && console.log("Error", err);
            });
        }

        // job.array.forEach(element => {

        // });
        // jobforEach=>API?
        // dispatch(jobList(result))
        //ids will be arrays use forEach
        // var index = getState().jobListdata.findIndex(x => x._id===id);
        // let list= [...getState().jobListdata];
        // //list[index]
        // _DEV && console.log(list[index]);
        //   list[index].customername=values.customerName;
        //   list[index].jobname=values.jobName;
        //   list[index].phone=values.phone;
        //   list[index].email=values.email;
        //   list[index].description=values.description;
        //   list[index].siteaddress=values.siteaddress;
        //  dispatch(jobList(list));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function editjob(id, values, location) {
  _DEV && console.log(values);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        customerId: values.customerId,
        customername: values.customerName,
        jobName: values.jobName,
        phone: values.phone,
        email: values.email,
        description: values.description,
        siteaddress: values.siteaddress,
        contractPrice: values.contractPrice,
        lat: values.coordinates.lat,
        lng: values.coordinates.lng,
        attachFile: values.attachFile,
        // status: values.status
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/job/updatejob?id=" + id, reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        if (result.message === "Edited") {
          dispatch(toast("success", "Job edited successfully!"));

          var index = getState().jobListdata.findIndex((x) => x._id === id);
          let list = [...getState().jobListdata];
          _DEV && console.log(list[index]);
          list[index] = result.result;
          // list[index].customername = values.customerName;
          // list[index].jobName = values.jobName;
          // list[index].phone = values.phone;
          // list[index].email = values.email;
          // list[index].description = values.description;
          // list[index].siteaddress = values.siteaddress;
          // list[index].contractPrice = values.contractPrice;
          // list[index].attachFile = values.attachFile;
          // list[index].status = values.status;

          dispatch(jobList(list));
        }
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
        dispatch(toast("error", err));
      });
  };
}

export function saveclockaction(id, action, role, location) {
  _DEV && console.log(action, "addc");
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        action: action,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/company/updateactionactivity?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        _DEV && console.log(getState());

        // dispatch(workerlist(result));

        if (result.message === "Updated") {
          if (role === "superadmin") {
            var index = getState().companylistdata.findIndex(
              (x) => x._id === id
            );
            let list = [...getState().companylistdata];
            _DEV && console.log(result.result.clockAction, "L1968");
            list[index].clockAction = result.result.clockAction;
            dispatch(companylist(list));

            let data = { ...getState().selectedcompanydata };
            data.clockAction = result.result.clockAction;
            dispatch(selectedcompanydata(data));
          } else {
            _DEV && console.log(getState().selectedcompanydata);
            let list = { ...getState().selectedcompanydata };

            list.clockAction = result.result.clockAction;
            _DEV && console.log(list);

            dispatch(selectedcompanydata(list));
          }
          dispatch(toast("success", "Clock action saved successfully !"));
        }

        // let data = getState().selectedcompanydata;
        // _DEV && console.log(data);
        // data.clockAction = action;
        // dispatch(selectedcompanydata(data));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}
// savetime

export function saveworkactivity(id, activity, role, location) {
  _DEV && console.log(activity);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        activity: activity,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/company/updateactionactivity?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        // dispatch(workerlist(result));
        _DEV && console.log(getState());

        if (result.message === "Updated") {
          if (role === "superadmin") {
            var index = getState().companylistdata.findIndex(
              (x) => x._id === id
            );

            let list = [...getState().companylistdata];

            list[index].workActivity = result.result.workActivity;

            dispatch(companylist(list));

            let data = { ...getState().selectedcompanydata };
            data.workActivity = result.result.workActivity;
            dispatch(selectedcompanydata(data));
          } else {
            _DEV && console.log(getState().selectedcompanydata);
            let list = { ...getState().selectedcompanydata };

            list.workActivity = result.result.workActivity;
            _DEV && console.log(list);
            dispatch(selectedcompanydata(list));
          }
          dispatch(toast("success", "Work activity saved successfully !"));
        }
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function editworker(id, values, location) {
  _DEV && console.log(values);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        name: values.name,
        title: values.title,
        phone: values.phone,
        address: values.address,
        email: values.email,
        contactName: values.contactName,
        password: values.password,
        image: values.profilePicture,
        attachFile: values.attachFile,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/user/updateuser?id=" + id, reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        var index = getState().workerList.findIndex((x) => x._id === id);
        let list = [...getState().workerList];
        //list[index]
        _DEV && console.log(list[index]);
        list[index].name = values.name;
        list[index].title = values.title;
        list[index].phone = values.phone;
        list[index].address = values.address;
        list[index].profilePicture = values.profilePicture;
        list[index].attachFile = values.attachFile;
        dispatch(workerlist(list));
        // dispatch(jobList(result))
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function editSuperAdminProfile(values, id, location) {
  _DEV && console.log(values);
  return (dispatch) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        enabled: "superadmin",
        name: values.name,
        // title: values.title,
        // phone: values.phone,
        // address: values.address,
        email: values.email,
        profilePicture: values.profilePicture,
        // contactName: values.contactName,
        // password: values.password
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/user/updateuser?id=" + id, reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        // dispatch in userdetail for superadmin

        dispatch(loggedinuser(result.result));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function updateenable(id, value, location) {
  return () => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        enabled: "yes",
        value: value,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/user/updateuser?id=" + id, reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        //   var index = getState().workerList.findIndex(x => x._id===id);
        //   let list= [...getState().workerList];
        //   //list[index]
        //   _DEV && console.log(list[index]);
        //   list[index].name=values.name;
        //   list[index].title=values.title;
        //   list[index].phone=values.phone;
        //   list[index].address=values.address;

        //  dispatch(workerlist(list));
        //   // dispatch(jobList(result))
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function editcustomer(id, values, location) {
  _DEV && console.log(id, values);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        address: values.address,
        companyname: values.companyname,
        contactname: values.contactname,
        email: values.email,
        primaryPhone: values.primaryPhone,
        secondaryPhone: values.secondaryPhone,
        description: values.description,
        latitude: values.latitude,
        longitude: values.longitude,
        leads: values.leads,
        salesMan: values.salesMan,
        profilePicture: values.profilePicture,
        attachFile: values.attachFile,
        attachedStatus: values.attachedStatus,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/customer/updatecustomer?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        // dispatch(jobList(result))
        //  _DEV && console.log(getState());
        var index = getState().customerList.findIndex((x) => x._id === id);

        let list = [...getState().customerList];
        _DEV && console.log(list[index]);
        list[index].companyname = values.companyname;
        list[index].primaryPhone = values.primaryPhone;
        list[index].secondaryPhone = values.secondaryPhone;
        list[index].description = values.description;
        list[index].contactname = values.contactname;
        list[index].email = values.email;
        list[index].address = values.address;
        list[index].leads = values.leads;
        list[index].salesMan = values.salesMan;
        list[index].profilePicture = values.profilePicture;
        list[index].attachFile = values.attachFile;
        list[index].attachedStatus = values.attachedStatus;
        dispatch(customerlist(list));

        // if(!result.error){

        // }
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

//This action to be called from profile
export function editcompanyadminfromprofile(id, values, location) {
  _DEV && console.log(values);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        profile: "yes",
        name: values.name,
        phone: values.phone,
        address: values.address,
        contactEmail: values.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/company/updatecompanywithadmin?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        if (result.message === "Company and it's admin Updated!!") {
          _DEV && console.log(getState());

          var index = getState().companylistdata.findIndex((x) => x._id === id);

          let list = [...getState().companylistdata];
          _DEV && console.log(list[index]);
          list[index].name = values.name;
          list[index].phone = values.phone;
          list[index].address = values.address;
          list[index].email = values.email;

          dispatch(companylist(list));
          dispatch(toast("success", "Company and it's email updated!"));
        }
        //Check for errors
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function editcompanyandadmin(id, values, location) {
  _DEV && console.log(id);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        name: values.companyname,
        phone: values.phone,
        address: values.address,
        contactEmail: values.contactEmail,
        contactName: values.contactName,
        password: values.contactPassword,
        contactPhone: values.contactPhone,
        profilePicture: values.profilePicture,
        companyEmail: values.companyEmail,
        attachFile: values.attachFile,
        // clearPassword: values.contactPassword
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/company/updatecompanywithadmin?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);

        _DEV && console.log(getState());
        let list = [...getState().companylistdata];
        _DEV && console.log(list);
        var index = list.findIndex((x) => x._id === id);
        _DEV && console.log(list[index]);
        list[index].name = values.companyname;
        list[index].phone = values.phone;
        list[index].address = values.address;

        dispatch(companylist(list));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function deletecustomer(id, location) {
  _DEV && console.log(id);
  return (dispatch, getState) => {
    const reqValues = {
      method: "DELETE",
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/customer/deletecustomer?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        _DEV && console.log(getState());

        var index = getState().customerList.findIndex((x) => x._id === id);

        let list = [...getState().customerList];

        list.splice(index, 1);
        _DEV && console.log(list, "list", Array.isArray(list));

        dispatch(customerlist(list));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function deletecompany(id, location) {
  _DEV && console.log(id);
  return (dispatch, getState) => {
    const reqValues = {
      method: "DELETE",
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/company/deletecompany?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        _DEV && console.log(getState());

        var index = getState().companylistdata.findIndex((x) => x._id === id);

        let list = [...getState().companylistdata];

        list.splice(index, 1);
        _DEV && console.log(list, "list", Array.isArray(list));

        dispatch(companylist(list));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

// to get manager

export function getManager(id, location) {
  return (dispatch) => {
    const reqValues = {
      method: "GET",
      headers: {
        accept: "application/xml",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl +
        "/user/getuserbyrole?role=manager&company=" +
        id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        dispatch(managerList(result));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function addManager(data, location) {
  return (dispatch, getState) => {
    _DEV && console.log(data);
    const reqValues = {
      method: "POST",
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        phone: data.phone,
        password: data.password,
        address: data.address,
        role: data.role,
        companyId: data.companyId,
        profilePicture: data.profilePicture || null,
        attachFile: data.attachFile || [],
        createdDate: Date.now(),
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/user", reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result, "L2200");
        if (result) {
          if (result.message) {
            dispatch(toast("error", result.message));
          } else if (result.role === "manager") {
            dispatch(managerList([...getState().managerList, result]));
          }
        }
      })
      .catch((err) => {
        _DEV && console.log(err);
      });
  };
}

export function editManager(id, values, location) {
  _DEV && console.log(values);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        name: values.name,
        phone: values.phone,
        address: values.address,
        email: values.email,
        contactName: values.contactName,
        password: values.password,
        image: values.profilePicture,
        attachFile: values.attachFile,
        profilePicture: values.profilePicture,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/user/updateuser?id=" + id, reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })

      .then((result) => {
        _DEV && console.log(result);
        var index = getState().managerList.findIndex((x) => x._id === id);
        let list = [...getState().managerList];
        _DEV && console.log(list[index]);
        list[index].name = values.name;
        list[index].phone = values.phone;
        list[index].address = values.address;
        list[index].profilePicture = values.profilePicture;
        list[index].attachFile = values.attachFile;
        dispatch(managerList(list));
        // location.push({pathname:'/dashboard/managerlist'});
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function deleteManager(id, location) {
  _DEV && console.log(id);
  return (dispatch, getState) => {
    const reqValues = {
      method: "DELETE",
      credentials: "include",
    };
    fetch(process.env.REACT_APP_apiurl + "/user/deleteuser?id=" + id, reqValues)
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        var index = getState().managerList.findIndex((x) => x._id === id);
        let list = [...getState().managerList];
        list.splice(index, 1);
        _DEV && console.log(list, "list", Array.isArray(list));
        dispatch(managerList(list));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function updateCompany(id, values, location) {
  _DEV && console.log(values);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        name: values.name,
        phone: values.phone,
        address: values.address,
        companyEmail: values.companyEmail,
        attachFile: values.attachFile,
        profilePicture: values.profilePicture,
        clockAction: values.clockAction,
        workActivity: values.workActivity,
        status: values.status || [],
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/company/updatecompany?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        let data = { ...getState().selectedcompanydata };
        data.profilePicture = values.profilePicture;
        dispatch(selectedcompanydata(data));
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

//for back to superadmin

export function removeCompanyData(result) {
  _DEV && console.log(result);
  return (dispatch) => {
    dispatch(selectedcompanydata(result));
    localStorage.removeItem("selectedCompanyId");
  };
}

export function saveLead(id, lead, role, location) {
  _DEV && console.log(lead);
  return (dispatch, getState) => {
    let leads = [];
    if (role === "superadmin") {
      var index1 = getState().companylistdata.findIndex((x) => x._id === id);

      let list1 = [...getState().companylistdata];

      leads = Array.isArray(list1[index1].leads) ? list1[index1].leads : [];

      leads.push(lead);
    } else {
      let list1 = { ...getState().selectedcompanydata };

      leads = Array.isArray(list1.leads) ? list1.leads : [];
      leads.push(lead);
    }
    _DEV && console.log(leads, lead, "L2427>>");
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        leads: leads,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    console.log(reqValues.body, "L2370>>Body");
    fetch(
      process.env.REACT_APP_apiurl + "/company/updateactionactivity?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result, "L2370>>");
        // dispatch(workerlist(result));
        _DEV && console.log(getState());

        if (result.message === "Updated") {
          if (role === "superadmin") {
            var index = getState().companylistdata.findIndex(
              (x) => x._id === id
            );

            let list = [...getState().companylistdata];

            list[index].leads = result.result.leads;

            dispatch(companylist(list));

            let data = { ...getState().selectedcompanydata };
            data.leads = result.result.leads;
            dispatch(selectedcompanydata(data));
          } else {
            _DEV && console.log(getState().selectedcompanydata);
            let list = { ...getState().selectedcompanydata };

            list.leads = result.result.leads;
            _DEV && console.log(list);

            dispatch(selectedcompanydata(list));
          }
          dispatch(toast("success", "Interest saved successfully!!"));
        }
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

export function saveProfileStatus(id, status, role, location) {
  _DEV && console.log(status);
  return (dispatch, getState) => {
    let statuses = [];
    if (role === "superadmin") {
      var index1 = getState().companylistdata.findIndex((x) => x._id === id);

      let list1 = [...getState().companylistdata];

      statuses = Array.isArray(list1[index1].status)
        ? list1[index1].status
        : [];

      statuses.push(status);
    } else {
      let list1 = { ...getState().selectedcompanydata };

      statuses = Array.isArray(list1.status) ? list1.status : [];
      statuses.push(status);
    }
    _DEV && console.log(statuses, status, "L2427>>");
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        status: statuses,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/company/updateactionactivity?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result, "L2370>>");
        // dispatch(workerlist(result));
        _DEV && console.log(getState());

        if (result.message === "Updated") {
          if (role === "superadmin") {
            var index = getState().companylistdata.findIndex(
              (x) => x._id === id
            );

            let list = [...getState().companylistdata];

            list[index].status = result.result.status;

            dispatch(companylist(list));

            let data = { ...getState().selectedcompanydata };
            data.status = result.result.status;
            dispatch(selectedcompanydata(data));
          } else {
            _DEV && console.log(getState().selectedcompanydata);
            let list = { ...getState().selectedcompanydata };

            list.status = result.result.status;
            _DEV && console.log(list);

            dispatch(selectedcompanydata(list));
          }
          dispatch(toast("success", "Status saved successfully!!"));
        }
      })
      .catch((err) => {
        _DEV && console.log("Error", err);
      });
  };
}

//to get manager list for pagination & search
export function getAllManagers(result) {
  _DEV && console.log(result);
  return (dispatch, getState) => {
    dispatch(managerList(result));
  };
}
{
  /* ======== Delete the status and update the old status array after delete */
}
export function getUpdatedStatus(result) {
  _DEV && console.log(result);
  return (dispatch, getState) => {
    // _DEV && console.log(getState().selectedcompanydata);
    // let list = { ...getState().selectedcompanydata };
    // list.status = result.result;
    // _DEV && console.log(list);

    dispatch(selectedcompanydata(result.result.comp));
  };
}

{
  /* ======== Delete the lead and update the old lead array after delete */
}
export function getUpdatedLead(result) {
  _DEV && console.log(result);
  return (dispatch, getState) => {
    // _DEV && console.log(getState().selectedcompanydata);
    // let list = { ...getState().selectedcompanydata };
    // list.status = result.result;
    // _DEV && console.log(list);

    dispatch(selectedcompanydata(result.result.comp));
  };
}

export function getEditedStatus(result) {
  _DEV && console.log(result);
  return (dispatch, getState) => {
    // _DEV && console.log(getState().selectedcompanydata);
    dispatch(selectedcompanydata(result.result));
  };
}

//to get customer list for pagination & search
export function getAllCustomers(result) {
  _DEV && console.log(result);
  return (dispatch, getState) => {
    dispatch(customerlist(result));
  };
}

//to get customer list for pagination & search
export function getAllWorkers(result) {
  _DEV && console.log(result);
  return (dispatch, getState) => {
    dispatch(workerlist(result));
  };
}

//to get customer list for pagination & search by superadmin
export function getAllCompany(result) {
  _DEV && console.log(result);
  return (dispatch, getState) => {
    dispatch(companylist(result));
  };
}

export function getSingleCompanyData(id, location) {
  _DEV && console.log(id);
  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/company/getSingle?id=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result, "2512>>");
        // let data = { ...getState().selectedcompanydata };
        let data = result.result;
        _DEV && console.log(data, "L1158");
        data.customerCount = result.nCustomrs;
        data.jobCount = result.nJobs;
        data.managerCount = result.nManagers;
        data.workerCount = result.nWorkers;
        dispatch(selectedcompanydata(data));
        localStorage.setItem("selectedCompanyId", id);
      })
      .catch((err) => {
        _DEV && console.log("Error" > err);
      });
  };
}

//to get customer list for pagination & search by superadmin
export function getAllJobs(result) {
  _DEV && console.log(result);
  return (dispatch, getState) => {
    dispatch(jobList(result));
  };
}

// for converstation
function allConversations(data) {
  return {
    type: "ALL_CONVERSATIONS_LIST",
    data: data,
  };
}

// update reducer for conversation
export function getAllConversations(result) {
  _DEV && console.log(result);
  return (dispatch, getState) => {
    dispatch(allConversations(result));
  };
}

// to change job visibilty to worker
export function changeVisibiltyStatus(id, data, location) {
  _DEV && console.log(id, data);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        text: data.status,
        startDate: data.startDate,
        endDate: data.endDate,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_apiurl + "/job/changeJobVisibility?jobId=" + id,
      reqValues
    )
      .then((result) => {
        if (result.status === 401) {
          location.push({
            pathname: "/home",
          });
        } else {
          return result.json();
        }
      })
      .then((result) => {
        _DEV && console.log(result);
        if (!result.error) {
          dispatch(toast("success", "Job's status changed successfully!!"));
          var index = getState().jobListdata.findIndex((x) => x._id === id);
          let list = [...getState().jobListdata];
          _DEV && console.log(list[index]);
          list[index] = result.result;

          dispatch(jobList(list));
        } else {
          dispatch(toast("error", "Server Error!!"));
        }
      })
      .catch((err) => {
        _DEV && console.log("Error" > err);
      });
  };
}

// to show message after assign job to worker

export function showMessages(status, message) {
  _DEV && console.log(status, message);
  return (dispatch) => {
    dispatch(toast(status, message));
  };
}

// to worker joblist reducer update
export function getAllworkerJobList(result) {
  _DEV && console.log(result);
  return (dispatch, getState) => {
    dispatch(workerJobList(result));
  };
}

// for tagged customer data

function customerTags(data) {
  return {
    type: "CUSTOMER TAG LIST",
    data: data,
  };
}

// for tagged customer data
export function getTaggedCustomer(result) {
  _DEV && console.log(result);
  return (dispatch, getState) => {
    dispatch(customerTags(result));
  };
}
