import React, { Component } from 'react';
import './addCreateConversation.css';
import '../AddEditCustomerForm/addedit.css';
import { withRouter } from 'react-router-dom';
import {
    Form,
    Input,
    Checkbox,
    Row,
    Col,
    Tooltip,
    Progress
} from 'antd';
import ButtonComponent from '../../Common/UiComponents/ButtonComponent';
import download from '../Images/download.png';
import TextArea from 'antd/lib/input/TextArea';

import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/min/dropzone.min.css';

import xlsImg from '../../Component/Images/teamclock file extension/xls.png';
import docImg from '../../Component/Images/teamclock file extension/doc.png';
import pdfImg from '../../Component/Images/teamclock file extension/pdf.png';
import jpgImg from '../../Component/Images/teamclock file extension/jpg.png';
import pngImg from '../../Component/Images/teamclock file extension/png.png';
import defaultImg from '../../Component/Images/teamclock file extension/intitled-1.png';
import * as actioncreators from '../../redux/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Avatar from 'react-avatar';
import EmailFieldForm from "../../Common/UiComponents/EmailFieldForm";
import returnback from '../../Component/Images/undo.png';
import CardSkeleton from '../../Common/UiComponents/CardSkeleton';
import { detectmob } from '../../utils/detectmob';
import close from '../../Component/Images/close.svg';
import axios from 'axios'


const uploadtext = '<div className="uploadtextcss"><p class="firstuploadtext">Drop your file here to start uploading</p><p class="seconduploadtext">For the most reliable operation,its best to keep your file under 1GB</p><p class="dropzonebtn">Select a file to upload</p></div>'

class AddCreateConversationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            attachFile: [],
            childConversation: [],
            checked: false,
            sharedWithCustomer: 0,
            visible: false,
            conversationId: '',// if id comes from email
            custEmail: '', // if cust enter email after clicking email,
            companyData: {}, // save company data if cust click on emal
            companyId: '',
            skeletonLoad: false,
            docfileData: {}
        };
        this.djsConfig = {
            addRemoveLinks: true,
            // acceptedFiles: 'image/jpeg,image/png,application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            autoProcessQueue: true,
            dictDefaultMessage: uploadtext,
            // errormessage:false

        };

        this.dropzone = null;
    }
    handleFileAdded(file) {
        console.log(file);
    }

    handlePost() {
        this.dropzone.processQueue();
    }

    /**
     * for model if customer click on email
     */
    showDrawer = () => {
        this.setState({
            visible: true
        });
    };
    onClose = () => {
        this.setState({
            visible: false
        });
    };

    docChange = (e) => {
        // this.showUploadingList()
        console.log(e.target.files[0], 'type files');
        this.setState({ docfileData: e.target.files[0] });
    
        let arr = [...this.state.attachFile]
        console.log(arr, '123456');
        let newArr = arr.filter(x => {
          return (
            x.name === e.target.files[0].name
          )
        })
    
        if (newArr.length > 0) {
          console.log(newArr, 'Duplicate File....');
          this.setState(
            {
              Open: true,
              error: true,
              doUploadCompletion: false,
              // uploadingDocs: false,
              message: "You are uploading duplicate document. Please selecet another document...",
            },
            () => setTimeout(this.notificationClose, 5000)
          );
        } else {
          this.setState({ uploadingDocs: true });
          const formData = new FormData();
          formData.append('file', e.target.files[0]);
    
          axios.request({
            method: "POST",
            url: process.env.REACT_APP_apiurl + "/fileupload?entityId=" + this.props.userDetail._id,
            data: formData,
            headers: {
              authorization: localStorage.getItem('token')
            },
            onUploadProgress: (p) => {
              this.setState({
                docFileProgress: ((p.loaded / p.total) * 100).toFixed(2)
              })
            }
    
          }).then(data => {
            console.log(data.data.result, 'Dattaa');
            this.setState({
              // loading: false,
              doUploadCompletion: true,
              uploadingDocs: false
            });
    
            let arr = [...this.state.attachFile]
            var obj = {};
            obj.name = data.data.result[0].originalname;
            obj.key = data.data.result[0].key;
            obj.mimetype = data.data.result[0].mimetype;
            arr.push(obj);
            this.setState({ attachFile: arr }, () =>
              setTimeout(this.docCompletion, 3000));
    
          })
        }
      }

    /**
     * to save email id if customer enter email id aftter clicking on email
     */
    saveEmail = (email) => {
        console.log(email, this.state.conversationId);
        if (email) {
            this.setState({ custEmail: email, visible: false })
            if (email && this.state.conversationId) {
                const reqValues = {
                    method: 'GET',
                    credentials: 'include'
                };
                fetch(process.env.REACT_APP_apiurl + '/conversation/getForCustomer?custmerEmail=' + email +
                    '&conversationId=' + this.state.conversationId, reqValues)
                    .then(result => {
                        if (result.status === 401) {
                          this.props.history.push({
                            pathname: '/home'
                          });
                        } 
                        else {
                          return result.json()
                        }
                      })
                    .then(result => {
                        console.log(result, 'L88');
                        if (!result.error) {
                            console.log(result.result, 'L90>>')
                            let arr = [];
                            arr.push(result.result)
                            this.setState({ childConversation: arr, companyData: result.result.companyId, skeletonLoad: false });
                        }
                    })
                    .catch(err => {
                        console.log('Error' > err);
                    });
            }
        }
    }

    componentDidMount() {
        console.log(this.props.location)
        this.setState({ skeletonLoad: true })
        console.log(this.props.userDetail, '>>>L84');

        function getQueryStringValue(key) { // to save conversation id cominf from email query or save company id coming from url

            console.log(key, 'L86>>')
            return decodeURIComponent(
                window.location.search.replace(
                    new RegExp(
                        '^(?:.*[&\\?]' +
                        encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') +
                        '(?:\\=([^&]*))?)?.*$',
                        'i'
                    ),
                    '$1'
                )
            );
        }

        let string = getQueryStringValue('id');
        let companyId = getQueryStringValue('companyId');
        console.log(string, companyId, 'L866>>')

        this.setState({ conversationId: string, companyId: companyId })

        if (string) {
            this.setState({ visible: true })
        }

        console.log(this.props.location.data, 'L67>>')
        console.log(this.props.location.customerData, 'L67>>')
        if (this.props.location.data) {  // to get child conversation
            const reqValues = {
                method: 'GET',
                credentials: 'include'
            };
            fetch(process.env.REACT_APP_apiurl + '/conversation/getChildconversation?conversationId=' + this.props.location.data._id +
                '&customerId=' + this.props.location.data.customerId._id, reqValues)
                .then(result => {
                    if (result.status === 401) {
                        this.props.history.push({
                            pathname: '/home'
                        });
                    }
                    else {
                        return result.json()
                    }
                })
                .then(result => {
                    console.log(result, 'L88');
                    if (!result.error) {
                        // console.log(result, 'L90>>')
                        this.setState({ childConversation: result.result, skeletonLoad: false });
                    }
                })
                .catch(err => {
                    console.log('Error' > err);
                });
        }

        else if (!this.props.location.data && !this.props.location.customerData) {
            this.props.history.go(-1);
        }


    }

    // to save parent or child conversation
    saveConversation = (e) => {
        e.preventDefault();
        console.log(this.state.conversationId)
        console.log(this.state.custEmail)
        this.props.form.validateFields((err, values) => {

            if (!err) {
                if (this.props.location.data) {  // for child conversation
                    console.log('child');
                    values.companyId = this.props.location.data.companyId;
                    values.parent = this.props.location.data._id;
                    values.userId = this.props.userDetail._id;
                    values.customerId = this.props.location.data.customerId._id;
                    values.attachFile = this.state.attachFile;
                    values.sharedWithCustomer = this.state.sharedWithCustomer;
                }
                else if (this.props.location.customerData) { // for parent conversaton
                    console.log('parent');
                    values.companyId = this.props.location.customerData.companyId;
                    values.userId = this.props.userDetail._id;
                    values.customerId = this.props.location.customerData._id;
                    values.attachFile = this.state.attachFile;
                    values.sharedWithCustomer = this.state.sharedWithCustomer;
                }

                else if (this.state.conversationId && this.state.custEmail) { // customer create conversation by clicking email
                    console.log(this.state.childConversation);
                    var arr = [...this.state.childConversation]
                    console.log(arr[0].customerId, 'L225');
                    values.companyId = this.state.companyData._id;
                    values.parent = arr[0].parent;
                    // values.userId = arr[0].customerId;
                    values.customerId = arr[0].customerId;
                    values.attachFile = this.state.attachFile;

                }
                const reqValues = {
                    method: 'POST',
                    body: JSON.stringify(values),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include'
                };
                console.log(values, 'L137');
                fetch(process.env.REACT_APP_apiurl + '/conversation', reqValues)
                    .then(result => {
                        if (result.status === 401) {
                            this.props.history.push({
                                pathname: '/home'
                            });
                        }
                        else {
                            return result.json()
                        }
                    })
                    .then(result => {
                        console.log(result, "L231")
                        if (!result.error) {
                            if (this.props.location.data || this.state.conversationId) {

                                let arr = [...this.state.childConversation];
                                arr.push(result.result);
                                this.setState({ childConversation: arr, attachFile: [] })

                                if (this.state.conversationId) {
                                    this.props.actions.showMessages('success', 'You have replied successfully')
                                }
                            }
                            else if (this.props.location.customerData) {
                                let arr = [...this.props.allConversations];
                                arr.push(result.result);
                                this.props.actions.getAllConversations(arr);
                                this.props.history.push({
                                    pathname: '/dashboard/customerhistory',
                                    search:
                                        "?companyId=" +
                                        this.state.companyId +
                                        "&customerId=" +
                                        this.props.location.customerData._id,
                                    data: this.props.location.customerData
                                })
                            }
                            console.log(result.result)
                            this.props.form.resetFields();
                            this.setState({ attachFile: [] })
                        }

                    })
                    .catch(err => {
                        console.log(err);
                    });

            }
        });

    }

    //for loading
    loading = () => {
        this.setState({ loading: true })
    }


    // to remove file during upload

    removeFileFromS3 = x => {
        console.log(x, "L254>>>");
        if (x !== "loader") {
            // const reqValues = {
            //     method: "PUT",
            // };
            // console.log(reqValues, "L247>>>");
            // fetch(process.env.REACT_APP_apiurl + "/deletefromS3?key=" + x, reqValues)
            //     .then(result => result.json())
            //     .then(result => {
            //         console.log(result, "L267>>>");
            //         if (!result.error) {
            let arr = [...this.state.attachFile];
            console.log(arr, "L270>>>");
            var newArr = arr.filter(z => {
                return z.key !== x;
            });
            console.log(newArr, "L274>>>");
            this.setState({
                attachFile: newArr
            });
            //     }
            // })
            // .catch(err => {
            //     console.log(err);
            //     // alert(err)
            // });
        } else {
            let arr = [...this.state.attachFile];
            var newArr = arr.filter(x => {
                return x !== "loader";
            });

            this.setState({ attachFile: newArr }, () =>
                console.log(this.state.attachFile, "L291>>>")
            );
        }
    };


    //to download file
    downloadFile = (data) => {
        console.log(data, navigator.userAgent.toLowerCase().search("iphone"), 'L99>>');
        const url = process.env.REACT_APP_apiurl + "/getFile?key=" + data.key;
        const a = document.createElement('a');
        a.href = url;
        a.target = detectmob() ? '_blank' : '_self';
        a.download = data.name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    // to check or uncheck checkbox
    checked = (e) => {
        console.log(e.target.checked, 'L207')
        if (e.target.checked === true) {
            this.setState({ sharedWithCustomer: 1 });
        }
        else {
            this.setState({ sharedWithCustomer: 0 });
        }

    }

    writingEmail = (x) => {
        console.log(x)
    }

    // on previous button click
    goBackCalled = () => {
        var arr = [...this.state.childConversation];
        var data = {}
        if (this.props.location.data) {
            data = this.props.location.data.customerId || arr[0].customerId;
        }
        else {
            data = this.props.location.customerData
        }
        this.props.history.push({
            pathname: '/dashboard/customerhistory',
            search:
                "?companyId=" +
                this.state.companyId +
                "&customerId=" +
                data._id,
            data: data
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        // const config = this.componentConfig;

        const config = {
            maxFiles: 10,
            maxFilesize: 10,
            iconFiletypes: ['.jpg', ".jpeg", '.png', '.gif', '.mp4', '.mov', '.m4c', '.mkv', '.xls', '.doc', '.pdf', 'xlsx', 'xlsm'],
            showFiletypeIcon: false,
            postUrl: process.env.REACT_APP_apiurl + "/fileupload?entityId=" + (this.props.selectedcompanydata._id || this.state.companyId),
            timeout: 2 * 5 * 2 * 30000
        };

        const djsConfig = this.djsConfig;

        // For a list of all possible events (there are many), see README.md!
        const eventHandlers = {
            init: dz => {
                console.log('dropzone', dz);
                this.dropzone = dz;
                // if (this.props.location.data) {
                //     console.log(this.props.location.data, 'L319>>')
                //     let data = this.props.location.data.attachFile && this.props.location.data.attachFile.length > 0 ? this.props.location.data.attachFile : this.state.attachFile;
                //     console.log(data, 'L335')
                //     let mockImages = data.map((x, idx) => {
                //         // console.log(x, idx ,'L317>>')
                //         return {
                //             name: x.name,
                //             type: 'image',
                //             key: x.key,
                //             mimetype: x.mimetype
                //         };
                //     });

                //     if (data.length > 0) {
                //         mockImages.map(mockFile => {
                //             console.log(mockFile, 'L330')
                //             this.dropzone.files.push(mockFile);
                //             this.dropzone.emit('addedfile', mockFile);
                //             this.dropzone.emit('thumbnail', mockFile, mockFile.mimetype.includes('word') ? docImg :
                //                 (mockFile.mimetype.includes("xls") || mockFile.mimetype.includes("sheet")) ? xlsImg :
                //                     mockFile.mimetype.includes("png") ? pngImg :
                //                         mockFile.mimetype.includes("image") ? jpgImg : defaultImg
                //             );
                //             this.dropzone.emit('complete', mockFile);
                //         });
                //         if (mockImages.length > 9) {
                //             this.dropzone.disable();
                //         }
                //     }
                // }
            },
            error: (e) => {
                // console.log(e.xhr.response, "error occured!");
                // e.xhr && console.log(JSON.parse(e.xhr.response))
                if (e.xhr && (e.xhr.response === "")) {
                    this.dropzone.disable()
                    this.props.actions.showMessages('error', 'max file limit exceeded!!')
                }
            },
            addedfile: (e) => { this.loading() },
            maxfilesreached: (e) => { console.log(e, "max file limit reached!") },
            maxfilesexceeded: (e) => { this.dropzone.removeFile(e); console.log(e, "max file limit exceeded!") },

            removedfile: (file) => {
                console.log(file, "L281")
                if (file.xhr) {
                    let response = JSON.parse(file.xhr.response);
                    console.log(response)
                    this.removeFileFromS3(response.result[0].key);
                    console.log(typeof this.state.attachFile, Array.isArray(this.state.attachFile), 'L376')
                    let arr = [...this.state.attachFile];
                    console.log(arr, "L287>>>");
                    var newArr = arr.filter(z => {
                        return z.key !== (response.result[0].key);
                    });
                    console.log(newArr, "L291>>>");
                    this.setState({
                        attachFile: newArr
                    }, () => {
                        if (this.state.attachFile.length < 20)
                            this.dropzone.enable()
                    }
                    );
                }
                else if (file.key) {
                    this.removeFileFromS3(file.key);
                    let arr = [...this.state.attachFile];
                    var newArr = arr.filter(z => {
                        return z.key !== file.key;
                    });
                    this.setState({
                        attachFile: newArr
                    }, () => {
                        if (this.state.attachFile.length < 20)
                            this.dropzone.enable()
                    }
                    );
                }
            },
            success: (file) => {
                console.log(file, 'L293>>');
                if (!file.type.includes('image')) {
                    const type = file.type
                    if (type.includes("pdf")) {

                        this.dropzone.emit("thumbnail", file, pdfImg);
                    }
                    else if (type.includes("word")) {

                        this.dropzone.emit("thumbnail", file, docImg);
                    }
                    else if (type.includes("xls") || type.includes("sheet")) {

                        this.dropzone.emit("thumbnail", file, xlsImg);

                    }
                    else if (type.includes("jpg") || type.includes("jpeg")) {

                        this.dropzone.emit("thumbnail", file, jpgImg);

                    }
                    else if (type.includes("png")) {

                        this.dropzone.emit("thumbnail", file, pngImg);
                    }
                    else if (type.includes("image")) {

                        this.dropzone.emit("thumbnail", file, jpgImg);
                    }
                    else {
                        this.dropzone.emit("thumbnail", file, defaultImg);

                    }
                }
                let response = JSON.parse(file.xhr.response);
                console.log(response, 'L324>>>')
                console.log(this.state.attachFile, typeof this.state.attachFile, Array.isArray(this.state.attachFile), 'L376')

                let image = [...this.state.attachFile];
                let obj = {};
                obj.key = response.result[0].key;
                obj.name = response.result[0].originalname;
                obj.mimetype = response.result[0].mimetype;
                image.push(obj);
                this.setState({ attachFile: image, loading: false }, () => {
                    console.log(this.state.attachFile, "L493>>");

                    if (this.state.attachFile.length > 9)
                        this.dropzone.disable()
                }
                );

            },
            thumbnail: (file, type) => {
                return type
            },
        };


        return (
            <div className="joblistssec jclockcontentarea createconversation">
                {this.state.conversationId && this.state.custEmail &&
                    <p className="showCompanyName">{this.state.companyData && this.state.companyData.name ? this.state.companyData.name : 'Not Available'}</p>}
                <Row>
                    <Col
                        xs={8} md={2}
                    // style={{ display: "inline-flex", justifyContent: 'flex-end' }}
                    // className="hidden-xs"
                    >
                        {!this.state.conversationId && !this.state.custEmail &&
                            <ButtonComponent
                                buttonText="Previous"
                                extraclass="returnpreviousbtn"
                                buttonimg={returnback} active
                                buttonClicked={this.goBackCalled} />}
                    </Col>
                    <Col
                        xs={16} md={22}>
                        {this.props.location.customerData &&
                            <div>
                                <h3 className="heding createconverstaintitle">Create Conversation</h3>
                            </div>
                        }
                    </Col>

                </Row>
                <div className="conversationcard">
                    <div className='wholeconversationpage'>
                        {/* If conversation continues start */}
                        {(this.props.location.data || this.state.conversationId) &&
                            <div className="customercardstart">
                                {this.props.location.data &&
                                    <div className="convofristdiv">
                                        <p className="convodateandtime">{this.props.location.data && moment(this.props.location.data.createdDate).format('MM/DD/YYYY h:mm')}</p>
                                        <p className="convoheading">{this.props.location.data && this.props.location.data.title ? this.props.location.data.title : ''}</p>
                                        <p className="convosubtext">{this.props.location.data && this.props.location.data.body ? this.props.location.data.body : ''}</p>
                                        <div className="attachedfile">
                                            <p className="customersecrow"><i className="fas fa-paperclip"></i> <span className="customerseclabel"> Attach File</span></p>
                                            <div className="forbetterview">

                                                {this.props.location.data.attachFile && this.props.location.data.attachFile.map(y => {
                                                    return (
                                                        <div className="customerimagediv" onClick={() => this.downloadFile(y)}>
                                                            <img src={y.mimetype.includes('pdf') ? pdfImg : y.mimetype.includes('doc') ? docImg : (y.mimetype.includes('jpg') || y.mimetype.includes('jpeg')) ?
                                                                jpgImg : y.mimetype.includes('png') ? pngImg : (y.mimetype.includes('xls') || y.mimetype.includes('sheet')) ? xlsImg : defaultImg} className="customerimg" />
                                                            <div className="downloadimgdiv">
                                                                <img src={download} className="downloadimg" />
                                                            </div>
                                                        </div>)
                                                })}
                                            </div>
                                        </div>
                                    </div>}
                                <hr className="lighborder" />
                                {/* <div className="convoscendddiv">
                                    <div className="forbetterview">
                                        <div className="customerimagediv">
                                            <img src={customer} className="customerimg" />
                                        </div>
                                        <div className="convotextsec">
                                            <p className="customersecrow"><span className="customerseclabel">Stephen J. Willmon</span>
                                                &nbsp; <span className="customersecvalue datevalueconvo">Jan 07,2020 10.30</span>
                                            </p>
                                            <p className="customersecrow"><span className="customersecvalue">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</span></p>
                                        </div>
                                    </div>
                                    <hr className="lighborder" />
                                </div> */}
                                <div className="conversationhappeneddiv">
                                    {this.state.skeletonLoad === true ?
                                        (<CardSkeleton />) :
                                        (this.state.childConversation && this.state.childConversation.map(x => {
                                            console.log(x, 'L605>>')

                                            return (
                                                <div className="convoscendddiv convothirddiv">
                                                    <div className="alreadysharedbutton">
                                                        {x.sharedWithCustomer !== 0 &&
                                                            <span>Shared &nbsp; <i className="fas fa-share"></i></span>}
                                                    </div>
                                                    <div className="forbetterview">
                                                        <div className="customerimagediv">
                                                            {/* <img src={docs} className="customerimg" /> */}
                                                            <Avatar name={x.createdBy ? x.createdBy.name : '' || x.customerId.contactname ? x.customerId.contactname : '' || this.props.userDetail ? this.props.userDetail.name : '' || this.state.custEmail ? this.state.custEmail : ''} size="100" color='#dadada' fgColor='#6d6d6d' />
                                                        </div>
                                                        <div className="convotextsec">
                                                            <p className="customersecrow"><span className="customerseclabel">{x.createdBy ? x.createdBy.name : '' || x.customerId.contactname ? x.customerId.contactname : '' || this.props.userDetail ? this.props.userDetail.name : '' || this.state.custEmail ? this.state.custEmail : ''}</span>
                                                                {/* <p className="customersecrow"><span className="customerseclabel">{'Customer'}</span> */}

                                                                &nbsp;  <span className="customersecvalue datevalueconvo">{x.createdDate && moment(x.createdDate).format('DD/MM/YYYY')}</span>
                                                            </p>
                                                            <p className="customersecrow"><span className="customersecvalue">{x.body ? x.body : ''}</span></p>
                                                            {x.attachFile &&
                                                                <div className="attachedfile">
                                                                    {x.attachFile && x.attachFile.length > 0 &&
                                                                        <p className="customersecrow"><i className="fas fa-paperclip"></i> <span className="customerseclabel"> Attach File</span></p>}
                                                                    <div className="forbetterview">
                                                                        {x.attachFile && x.attachFile.map(y => {
                                                                            console.log(x, 'L4630')
                                                                            return (
                                                                                <div className="customerimagediv" onClick={() => this.downloadFile(y)}>
                                                                                    <img src={y.mimetype.includes('pdf') ? pdfImg : y.mimetype.includes('doc') ? docImg : (y.mimetype.includes('jpg') || y.mimetype.includes('jpeg')) ?
                                                                                        jpgImg : y.mimetype.includes('png') ? pngImg : (y.mimetype.includes('xls') || y.mimetype.includes('sheet')) ? xlsImg : defaultImg} className="customerimg" />
                                                                                    <div className="downloadimgdiv">
                                                                                        <img src={download} className="downloadimg" />
                                                                                    </div>
                                                                                </div>)
                                                                        })}
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                    {/* <hr className="lighborder" /> */}
                                                </div>

                                            )

                                        }))}
                                </div>
                                <hr className="lighborder" />
                            </div>}

                        {/* If coversation continues end */}
                        <div className={(this.props.location.customerData && "onlyforaddconversation") + ' ' + "conversation-replysection"}>

                            <Form layout="vertical">
                                {/* <div className={(this.props.location.customerData && "createconversationformstart") + ' ' + "conversationformstart"}> */}
                                <Row gutter={24} >
                                    <Col md={14} xs={24}>
                                        {this.props.location.customerData &&
                                            <div>
                                                {/* <h3 className="heding createconverstaintitle">Create Conversation</h3> */}
                                                {/* <p className="formsubsectiontitle"><span>
                                            1
                                        </span>Your basic information</p> */}
                                                <Form.Item className="addtitleform" label="Title">
                                                    {getFieldDecorator("title", {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Please enter a title!"
                                                            }
                                                        ]
                                                    })(<Input type="text" autocomplete="off"
                                                    />)}
                                                </Form.Item>
                                            </div>}
                                        {/* ----For Create Conversation end----- */}

                                        {/* <div className={(this.props.location.customerData && "createconversationformstart") + ' ' + "conversationformstart"}> */}
                                        <div className="replylefftmaindiv">
                                            {(this.props.location.data || this.state.conversationId) ?
                                                <h3 className="heding">Add Reply</h3> :
                                                <h3 className='writecommnetheading'>Write Comment</h3>}
                                            <div className="replyleftdiv">
                                                {/* <div className="customerimagediv">
                                                <img src={customer} className="customerimg" />
                                            </div> */}
                                                <div className="coversationtextareadiv">
                                                    <Form.Item>
                                                        {getFieldDecorator("body", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Please Your Comment!"
                                                                }
                                                            ]
                                                        })(
                                                            <TextArea
                                                                placeholder="Write your comments here..."
                                                                rows={10}
                                                                autocomplete="off"
                                                            />)}
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={10} xs={24}>
                                        <div className="replyrighttmaindiv">
                                            <p className="formsubsectiontitle">Attachments</p>
                                            <Form.Item className="coversationdropzondiv">
                                                <div className="attachmentsection">
                                                    <div className='attachmentsection'>
                                                        {this.state.docfileData.name && this.state.uploadingDocs === true ?
                                                            <div>
                                                                <div className='forLectureVideoUpload'>
                                                                    <div className='uploadFileListing'>
                                                                        {/*<p>{this.state.fileData.name}</p>*/}
                                                                        <Tooltip disableFocusListener placement="bottom-start"
                                                                            title={this.state.docfileData.name}>
                                                                            <p className="uploadFileName">{this.state.docfileData.name}</p>
                                                                        </Tooltip>
                                                                        <div className='uploadingStatus'>
                                                                            {this.state.uploadingDocs === true ?
                                                                                <div className="fileUploadProgress progressUploading">
                                                                                    <p>Uploading</p>
                                                                                    <Progress
                                                                                        showInfo={true}
                                                                                        variant="determinate"
                                                                                        percent={this.state.docFileProgress}
                                                                                        className='linearProgressCls' />
                                                                                </div> :
                                                                                (this.state.doUploadCompletion === true ?
                                                                                    (<div>
                                                                                        <p className='uploadComplete'>
                                                                                            Completed
                                                                                            <Progress
                                                                                                showInfo={true}
                                                                                                variant="static"
                                                                                                percent={100}
                                                                                                className='linearProgressCls' />

                                                                                        </p>

                                                                                    </div>) : null)}


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            : (this.state.attachFile.length > 0 ?
                                                                <div>
                                                                    {this.state.attachFile.map(x => {
                                                                        return (
                                                                            <div className='uploadedfilelisting'>
                                                                                <p className="uploadFileName uploadDocName">{x.name}</p>
                                                                                {/* <CloseOutlined /> */}
                                                                                <img src={close} onClick={() => this.removeFileFromS3(x.key)} />
                                                                            </div>

                                                                        )
                                                                    })}
                                                                    <div className='fileuploadbutton'>
                                                                        <ButtonComponent buttonText='Select a file to upload' extraclass='uploadfilebtnui' active buttonClicked={(e) => e.preventDefault()}
                                                                        />
                                                                        <input
                                                                            type="file" accept="application/*,.csv,.xlsm,.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                                                            onChange={this.docChange} />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='uploadVideoInput'>
                                                                    <p className='uploadtexthead'>Select your file here to start uploading</p>
                                                                    <p className='uploadtextsubhead'>For the most reliable operation, its best to keep your file under 1GB</p>
                                                                    <div className='fileuploadbutton'>
                                                                        <ButtonComponent buttonText='Select a file to upload' extraclass='uploadfilebtnui' active buttonClicked={(e) => e.preventDefault()}
                                                                        />
                                                                        <input
                                                                            type="file" accept="application/*,.csv,.xlsm,.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                                                            onChange={this.docChange} />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>                                                </div>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                                {/* </div> */}

                                <div className="conversationbtndiv">
                                    {!this.state.conversationId &&
                                        <Form.Item>
                                            <Checkbox className="conversationchkbox" onChange={this.checked}>Send this reply to customer</Checkbox>
                                        </Form.Item>}
                                    <Form.Item>
                                        <ButtonComponent buttonText="Submit" active buttonextraclass="editbtndesign" buttonClicked={this.saveConversation} />
                                    </Form.Item>
                                </div>

                            </Form>
                        </div>
                    </div>
                </div>
                <EmailFieldForm closeClick={this.onClose} visible={this.state.visible} saveEmail={this.saveEmail}
                />
            </div>
        );
    }
}

const WrappedAddCreateConversationForm = Form.create()(AddCreateConversationForm);

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(WrappedAddCreateConversationForm)
);



            // export default withRouter(AddCreateConversationForm);
